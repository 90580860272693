import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { useAtomsDebugValue } from 'jotai/devtools';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import '@aws-amplify/ui-react/styles.css';
import '@fontsource/noto-sans/100.css';
import '@fontsource/noto-sans/200.css';
import '@fontsource/noto-sans/300.css';
import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/600.css';
import '@fontsource/noto-sans/700.css';
import '@fontsource/noto-sans/800.css';
import '@fontsource/noto-sans/900.css';

import theme from './theme';
import './states';
import App from './App';

import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);
}

const root = createRoot(
  document.getElementById('root') as HTMLElement
);

const DebugAtoms = () => {
  useAtomsDebugValue();
  return null;
};

export const cssCache = createCache({
  key: 'app',
  nonce: '344f553f-5eef-4587-a808-7a482f5aa30b',
  prepend: true,
});

root.render(
  <StrictMode>
    <DebugAtoms />
    <CacheProvider value={cssCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </CacheProvider>
  </StrictMode>
);
