// * Auth component
import { useEffect } from 'react';
import {
  Authenticator,
  useAuthenticator,
  VisuallyHidden,
} from '@aws-amplify/ui-react';
import { Auth as AmplifyAuth } from 'aws-amplify';
import { useAtom } from 'jotai';

import {
  dashboardsAtom,
  uiControlAtom,
  userAtom,
} from '../../states';
import { lambdaInvoke } from '../../services/lambda';

const Auth = () => {
  const [, setDashboards] = useAtom(dashboardsAtom);
  const [uiControl, setUiControl] = useAtom(uiControlAtom);
  const [, setUser] = useAtom(userAtom);

  const { authStatus, user: amplifyUser } = useAuthenticator(
    (context) => [context.authStatus, context.user]
  );

  const initFetch = async (id_token: string) => {
    try {
      // * Shows loader
      setUiControl({
        ...uiControl,
        loading: true,
      });

      // * returns url, user and dashboards
      const data = await lambdaInvoke({
        id_token,
        dashboardId: '',
        dashboardMode: 'landing',
      });

      if (data) {
        const { user, url, dashboards } = data;
        if (dashboards) {
          setDashboards(dashboards);
          setUiControl({
            ...uiControl,
            currentDashboard: {
              id: dashboards[0].id,
              url,
            },
          });
        }

        setUser(user);

        return;
      }

      throw new Error('Empty Data!');
    } catch (error) {
      setUiControl({
        ...uiControl,
        loading: false,
        error: 'Sorry we were unable to load the dashboards',
      });
    }
  };

  useEffect(() => {
    if (authStatus === 'authenticated') {
      initFetch(
        amplifyUser
          .getSignInUserSession()
          ?.getIdToken()
          .getJwtToken() || ''
      );
    }

    if (authStatus === 'unauthenticated') {
      AmplifyAuth.federatedSignIn({ customProvider: 'StatsOktaIdp' });
    }
  }, [amplifyUser, authStatus]);

  return (
    <VisuallyHidden>
      <Authenticator />
    </VisuallyHidden>
  );
};

export default Auth;
