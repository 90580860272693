// * Layouts Component
import {
    Alert,
    Box,
    Backdrop,
    CircularProgress,
    Fade,
    IconButton,
    Stack
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useAuthenticator } from "@aws-amplify/ui-react"
import { useAtom } from "jotai"
import { useEffect } from "react"
import { embedDashboard } from "amazon-quicksight-embedding-sdk"

import { lambdaInvoke } from "../../services/lambda"
import { emptyStringObject } from "../../utils"
import { uiControlAtom, userAtom } from "../../states"
import Header from "./Header"
import Sidebar from "./Sidebar"

const Layouts = () => {
    const { authStatus, user: amplifyUser } = useAuthenticator((context) => [
        context.authStatus,
        context.user
    ])

    const [uiControl, setUiControl] = useAtom(uiControlAtom)
    const [, setUser] = useAtom(userAtom)

    const handleErrorClose = () => {
        setUiControl({
            ...uiControl,
            error: ""
        })
    }

    useEffect(() => {
        if (!emptyStringObject(uiControl.incomingDashboard)) {
            const handleDashboardChange = async (
                dashboardId: string,
                dashboardMode: string
            ) => {
                const id_token =
                    amplifyUser
                        .getSignInUserSession()
                        ?.getIdToken()
                        .getJwtToken() || ""

                try {
                    // * Shows loader
                    setUiControl({
                        ...uiControl,
                        loading: true
                    })

                    const data = await lambdaInvoke({
                        id_token,
                        dashboardId,
                        dashboardMode
                    })

                    if (data) {
                        const { user, url } = data

                        setUser(user)

                        setUiControl({
                            ...uiControl,
                            menuOpened: false,
                            currentDashboard: {
                                id: dashboardId,
                                url
                            },
                            incomingDashboard: {
                                id: "",
                                mode: ""
                            }
                        })

                        return
                    }

                    throw new Error("Empty Data!")
                } catch (error) {
                    setUiControl({
                        ...uiControl,
                        incomingDashboard: {
                            id: "",
                            mode: ""
                        },
                        loading: false,
                        error: "Sorry we were unable to load the dashboards"
                    })
                }
            }

            handleDashboardChange(
                uiControl.incomingDashboard.id,
                uiControl.incomingDashboard.mode
            )
        }
    }, [uiControl.incomingDashboard])

    useEffect(() => {
        if (!emptyStringObject(uiControl.currentDashboard)) {
            const container = document.getElementById(
                "quicksight-embeded"
            ) as HTMLElement

            if (container.childElementCount !== 0) {
                // * Clears children
                container.innerHTML = ""
            }

            const dashboardIframe = embedDashboard({
                url: uiControl.currentDashboard.url,
                container,
                scrolling: "no",
                height: "100%",
                width: "100%",
                locale: "en-US",
                footerPaddingEnabled: true,
                defaultEmbeddingVisualType: "TABLE"
            })

            // * When dashboard has error
            dashboardIframe.on("error", () => {
                setUiControl({
                    ...uiControl,
                    error: "Sorry we encountered an error. Please navigate to other pages or refresh this page to continue."
                })
            })

            // * Triggered when dashboard fully loaded
            dashboardIframe.on("load", () => {
                setUiControl({
                    ...uiControl,
                    error: "",
                    loading: false
                })
            })
        }
    }, [uiControl.currentDashboard])

    const error = (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                zIndex: (theme) => theme.zIndex.appBar + 1,
                width: "100%",
                marginTop: { xs: "56px", sm: "64px" }
            }}
        >
            <Fade in={true}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleErrorClose}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {uiControl.error}
                </Alert>
            </Fade>
        </Stack>
    )

    const loader = (
        <Backdrop
            sx={{
                color: "info.main",
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )

    return (
        <Box sx={{ display: "flex" }}>
            {uiControl.error && <>{error}</>}
            {authStatus === "unauthenticated" && <>{loader}</>}
            {authStatus === "authenticated" && (
                <>
                    {uiControl.loading && <>{loader}</>}
                    <Header />
                    <Sidebar />
                    <Box
                        id="quicksight-embeded"
                        component="main"
                        sx={{
                            width: "100%",
                            height: "calc(100% - 64px)",
                            top: { xs: "56px", sm: "64px" },
                            position: "fixed"
                        }}
                    />
                </>
            )}
        </Box>
    )
}

export default Layouts
