import { atom } from 'jotai';

type IControlState = {
  loading: boolean;
  accountDropdownAnchor: null | HTMLElement;
  menuOpened: boolean;
  currentDashboard: {
    id: string;
    url: string;
  };
  incomingDashboard: {
    id: string;
    mode: string;
  };
  error: string;
};

export const uiControlAtom = atom<IControlState>({
  loading: false,
  accountDropdownAnchor: null,
  menuOpened: false,
  currentDashboard: {
    id: '',
    url: '',
  },
  incomingDashboard: {
    id: '',
    mode: '',
  },
  error: '',
});

export type IDashboardState = {
  name: string;
  id: string;
};

export const dashboardsAtom = atom<IDashboardState[]>([]);

export type IUserState = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

export const userAtom = atom<IUserState>({
  firstName: '',
  lastName: '',
  email: '',
  role: '',
});

if (process.env.NODE_ENV === 'development') {
  dashboardsAtom.debugLabel = 'Dashboards Atom';
  uiControlAtom.debugLabel = 'Control Atom';
  userAtom.debugLabel = 'User Atom';
}
