// * Lambda Functions
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda'
import { toUtf8, fromUtf8 } from '@aws-sdk/util-utf8-browser'
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'

import { IDashboardState } from '../states'

type IUser = {
    email: string
    firstName: string
    lastName: string
    role: string
}

// ? dashboardMode: 3 types landing | change_dashboard | start_authoring
export const lambdaInvoke = async ({
    id_token,
    dashboardMode,
    dashboardId,
}: {
    id_token: string
    dashboardMode: string
    dashboardId: string
}) => {
    try {
        const loginProvider = `cognito-idp.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_AWS_USER_POOLS_ID}`
        const data = await new LambdaClient({
            region: process.env.REACT_APP_AWS_COGNITO_REGION,
            credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({
                    region: process.env.REACT_APP_AWS_COGNITO_REGION,
                }),
                identityPoolId:
                    process.env.REACT_APP_AWS_IDENTITY_POOL_ID || '',
                logins: {
                    [loginProvider]: id_token,
                },
            }),
        }).send(
            new InvokeCommand({
                FunctionName: process.env.REACT_APP_AWS_LAMBDA_FUNCTION_NAME,
                InvocationType: 'RequestResponse',
                LogType: 'Tail',
                Payload: fromUtf8(
                    JSON.stringify({
                        id_token,
                        mode: dashboardMode,
                        input: dashboardId,
                    })
                ),
            })
        )

        if (data.Payload) {
            const parsedData = JSON.parse(toUtf8(data.Payload))

            const user: IUser = JSON.parse(parsedData.user)

            const body = JSON.parse(parsedData.body)

            if (typeof body === 'string') {
                return {
                    user,
                    url: body,
                }
            }

            if (body.Dashboards && Array.isArray(body.Dashboards)) {
                const arry = body.Dashboards as Array<{
                    DashboardId: string
                    Name: string
                    url?: string
                }>

                if (arry.length > 0) {
                    const dashboards: IDashboardState[] = arry.map(
                        ({ DashboardId: id, Name: name }) => ({
                            id,
                            name,
                        })
                    )

                    const url: string = arry[0].url || ''

                    return {
                        user,
                        dashboards,
                        url,
                    }
                }
            }
        }

        throw new Error('No data')
    } catch (error) {
        console.error(error as Error)
    }
}
