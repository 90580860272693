// * Sidebar Compoenent
import {
    Box,
    Button,
    Drawer,
    List,
    Stack,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography
} from "@mui/material"
import { useAtom } from "jotai"
import { Auth as AmplifyAuth } from "aws-amplify"
import { uiControlAtom, userAtom, dashboardsAtom } from "../../states"
import { emptyStringObject, isAuthor } from "../../utils"

const drawerWidth = 240

const Sidebar = () => {
    const [uiControl, setUiControl] = useAtom(uiControlAtom)
    const [user] = useAtom(userAtom)
    const [dashboards] = useAtom(dashboardsAtom)

    const handleDrawerToggle = () => {
        setUiControl({
            ...uiControl,
            menuOpened: !uiControl.menuOpened
        })
    }

    const handleLogout = async () => {
        await AmplifyAuth.signOut()
    }

    const container =
        window !== undefined ? () => window.document.body : undefined

    return (
        <Box component="nav">
            <Drawer
                color="palette.dark"
                container={container}
                variant="temporary"
                open={uiControl.menuOpened}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: "#292929",
                        color: "#FFFFFF"
                    }
                }}
                sx={{
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth
                    }
                }}
            >
                <Box sx={{ textAlign: "center", pt: 2 }}>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        sx={{ display: { xs: "block", sm: "none" }, flex: 2 }}
                    >
                        {!emptyStringObject(user) && (
                            <>
                                <Typography variant="body1" component="span">
                                    {`Welcome, `}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    sx={{ fontWeight: "bold" }}
                                >
                                    {`${user.firstName} ${user.lastName}`}
                                </Typography>
                            </>
                        )}
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </Stack>
                    <Box
                        sx={{
                            p: 2,
                            display: { xs: "block", sm: "none" }
                        }}
                    />
                    <Typography variant="h5">Dashboards</Typography>
                    <List>
                        {dashboards.map((dashboard) => (
                            <ListItem
                                key={dashboard.id}
                                disablePadding
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "#213D4D"
                                    }
                                }}
                            >
                                <ListItemButton
                                    selected={
                                        uiControl.currentDashboard.id ===
                                        dashboard.id
                                    }
                                    sx={{
                                        textAlign: "left"
                                    }}
                                >
                                    <ListItemText
                                        primary={dashboard.name}
                                        onClick={() =>
                                            setUiControl({
                                                ...uiControl,
                                                incomingDashboard: {
                                                    id: dashboard.id,
                                                    mode: "change_dashboard"
                                                }
                                            })
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    )
}

export default Sidebar
