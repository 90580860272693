// * Theme file
import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    background: {
      default: '#ededed',
    },
  },
  typography: {
    fontFamily: ['Noto Sans', 'sans-serif'].join(','),
  },
});

export default theme;
