// * App layouts
import { Amplify } from "aws-amplify"
import { Authenticator } from "@aws-amplify/ui-react"

import Auth from "./components/Auth"
import Layouts from "./components/Layouts"

Amplify.configure({
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id:
        process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    oauth: {
        domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
        scope: ["email", "openid", "profile"],
        redirectSignIn: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_IN_URL,
        redirectSignOut: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_OUT_URL,
        clientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
        responseType: "token"
    }
})

function App() {
    return (
        <Authenticator.Provider>
            <Auth />
            <Layouts />
        </Authenticator.Provider>
    )
}

export default App
